import React from "react";
import "./footer.scss";

const FooterComponent = () => {
  return (
    <div id="footer">
      <p>Code by Zero - 0909240886</p>
    </div>
  );
};

export default FooterComponent;
